<template>
  <ModalComponent :active="modalActive">
    <aside class="modal-info">
      <img class="modal-info__image" src="@/assets/img/logo.png" alt="">
      <h3>Datos enviados correctamente</h3>
      <button @click="closeModal" class="modal-info__button">Ok</button>
    </aside>
  </ModalComponent>
</template>

<script>
import ModalComponent from './ModalComponent.vue'
import { EventBus } from '@/event-bus';

export default {
  components: { ModalComponent },
  name:"modalInfo",
  data() {
    return {
      modalActive: false,
    }
  },
  methods: {
    closeModal() {
      this.modalActive = false
    },
  },
  mounted() {
    EventBus.$on("openModalInfo", () => {
      this.modalActive = true
    })
  },
}
</script>

<style>
  .modal-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary);
    width: 350px;
    height: auto;
    padding: 25px;
  }
  .modal-info__image {
    width: 100px;
  }
  .modal-info__button {
    background: var(--secondary);
    padding: 10px;
    width: 100px;
    color: #fff;
  }
</style>