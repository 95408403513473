<template>
  <ModalComponent :active="modalActive">
    <div class="modal-form">
      <button class="modal-form__button-close" @click="closeModal">
        <img class="button-close__image" src="@/assets/img/close-button.png" alt="">
      </button>
      <img class="modal-form__image" :src="image" alt="">
      <div class="modal-form__content">
        <h2 class="modal-form__title" v-html="title"></h2>
        <form
          @submit.prevent="() => {
            checkboxForm.form = false
            validateForm.validate = true
            validateForm.phone = false
            validateForm.required = false
            if(!checkboxForm.value) {
              checkboxForm.form = true
            } else
            if(form.phone === '') {
              validateForm.required = true
            } else
            if(form.phone.length !== 7 && form.phone.length !== 10) {
              validateForm.phone = true
            } else {
              sendData(form)
              validateForm.validate = false
            }
          }"
          class="modal-form__inputs"
        >
          <!-- <input v-model="form.name" class="custom-input custom-input--modal" name="name" placeholder="Ingresa tu nombre" type="text"> -->
          <input v-mask="'##########'" v-model="form.phone" class="custom-input custom-input--modal" name="phone" placeholder="Ingresa tu número" type="tel">
          <div class="form__checkbox form__checkbox--modal">
            <input v-model="checkboxForm.value" type="checkbox" name="older" id="older-modal">
            <label class="checkbox__label" for="older-modal">Confirmo que soy mayor de edad y autorizo el uso mis datos personales de acuerdo a la política de Phonemaster distribuidor autorizado por ETB.</label>
          </div>
            <button class="custom-button custom-button--modal">Llámame</button>
          <small class="error--text" v-if="!checkboxForm.value && checkboxForm.form">Se requiere ser mayor de edad y aceptar los terminos y condiciones</small>
          <template v-if="validateForm.validate">
            <small class="error--text" v-if="validateForm.phone">El número debe tener 7 o 10 digitos</small>
            <small class="error--text" v-if="validateForm.required">Todos los campos son requeridos</small>
          </template>
          <p>¡CONECTAMOS TUS SERVICIOS EN 24 HORAS O MENOS!</p>
          <p><a href="#teminos-y-condiciones">*Aplican términos y condiciones</a> política exclusiva </p>
          <p>de PHONEMASTER</p>
        </form>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
import { EventBus } from '@/event-bus';
import ModalComponent from '@/components/ModalComponent.vue'

export default {
  props: {
    image: String,
    phone: String,
    title: String,
  },
  components: {
    ModalComponent,
  },
  data() {
    return {
      form: {
        phone: '',
        // name: '',
      },
      checkboxForm: {
        value: true,
        form: false,
      },
      validateForm: {
        required: false,
        phone: false,
        validate: false
      },
      modalActive: false,
    }
  },
  mounted() {
    EventBus.$on("openModalForm", () => {
      this.modalActive = true
    })
  },
  methods: {
    closeModal() {
      this.modalActive = false
    },
    sendData: async (form) => {
      try {
        window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:"lead registro popup", eventLabel:"Leads"})
        const response = await fetch('https://landing.inoutdelivery.com/api/neo', {
          method: 'POST',
          body: JSON.stringify(form),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if(response.ok) {
          EventBus.$emit("openModalInfo")

        }
        return response
      } catch (error) {
        console.error(error)
      }
    }
  },
}
</script>

<style>
  .modal-form {
    max-height: 100vh;
    max-width: 500px;
    width: 100%;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    overflow: auto;
  }
  .modal-form__image {
    width: 100%;
  }

  .modal-form__button-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .button-close__image {
    width: 40px;
  }

  .modal-form__content {
    text-align: center;
    padding: 0 10px 20px;
  }

  .modal-form__title {
    font-size: 2.15rem;
    margin-bottom: 15px;
  }

  .modal-form__inputs {
    padding: 0 20px;
  }

  .custom-input--modal {
    font-size: 1.1rem;
    height: 40px;
    background-color: #e9e9e9;
  }
  .custom-input--modal::placeholder {
    color: var(--secondary);
    opacity: 0.2;
  }

  .form__checkbox--modal {
    margin: 15px 0;
  }

  .form__checkbox--modal input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .form__checkbox--modal .checkbox__label {
    font-size: .6rem;
    text-align: start;
  }

  .custom-button.custom-button--modal {
    font-size: 1.7rem;
    height: auto;
    padding: 5px;
    background-color: var(--secondary);
  }

  .modal-form__terms {
    text-align: right;
    display: block;
    font-size: 0.8rem;
  }
  .modal-form__inputs .error--text{
    width: 100%;
    max-width: 100%;
    font-size: .8rem;
  }

  @media screen and (min-width: 550px) {
    .modal-form__content {
      padding-top: 30px;
    }
    .modal-form__button-close {
      top: -10px;
      right: -10px;
    }
  }
</style>