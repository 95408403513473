<template>
  <div class="slider__container">
    <div
      ref="slider"
      id="slider"
      class="slider"
    >
      <div class="wrapper" :class="{'fix-space': fixingSpace}">
        <div
          ref="sliderItems"
          id="items"
          class="items"
        >
          <button
            @dragstart="dragStart"
            @drag="dragAction"
            @dragend="dragEnd"
            @click="openModalForm"
            ref="firstSlide"
            class="slide"
          >
            <img class="slide__image" :src="'https://landing.inoutdelivery.com' + sliders[sliders.length - 1].attributes.url"/>
          </button>
          <button
            v-for="(slideItem, index) in sliders"
            :key="index"
            @dragstart="dragStart"
            @drag="dragAction"
            @dragend="dragEnd"
            @click="openModalForm"
            ref="slides"
            class="slide"
          >
            <img class="slide__image" :src="'https://landing.inoutdelivery.com'+ slideItem.attributes.url"/>
          </button>
          <button
            @dragstart="dragStart"
            @drag="dragAction"
            @dragend="dragEnd"
            @click="openModalForm"
            ref="lastSlide"
            class="slide"
          >
            <img class="slide__image" :src="'https://landing.inoutdelivery.com' + sliders[0].attributes.url"/>
          </button>
        </div>
      </div>
      <a @click="()=>{shiftSlide(-1)}" id="prev" class="control prev">
        <img class="control__image" src="@/assets/img/arrow-left.png" alt="">
      </a>
      <a @click="()=>{shiftSlide(1)}" id="next" class="control next">
        <img class="control__image" src="@/assets/img/arrow-right.png" alt="">
      </a>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
  props: {
    sliders: Array,
  },
  data() {
    return {
      posX1 : 0,
      posX2 : 0,
      posInitial : null,
      posFinal : null,
      threshold : 100,
      slideSize : null,
      index : 0,
      allowShift : true,
      fixingSpace : false,
      interval : null,
    }
  },
  methods: {
    openModalForm () {
      window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:"Quiero este Plan", eventLabel:"Leads"})
      EventBus.$emit("openModalForm")
    },
    dragStart(e) {
      e = e || window.event;
      e.preventDefault();
      this.posInitial = this.$refs.sliderItems.offsetLeft;

      if (e.type == "touchstart") {
        this.posX1 = e.touches[0].clientX;
      } else {
        this.posX1 = e.clientX;
        document.onmouseup = this.dragEnd;
        document.onmousemove = this.dragAction;
      }
    },
    dragAction(e) {
      e = e || window.event;

      if (e.type == "touchmove") {
        this.posX2 = this.posX1 - e.touches[0].clientX;
        this.posX1 = e.touches[0].clientX;
      } else {
        this.posX2 = this.posX1 - e.clientX;
        this.posX1 = e.clientX;
      }
      this.$refs.sliderItems.style.left = this.$refs.sliderItems.offsetLeft - this.posX2 + "px";
    },
    dragEnd() {
      this.posFinal = this.$refs.sliderItems.offsetLeft;
      if (this.posFinal - this.posInitial < - this.threshold) {
        this.shiftSlide(1, "drag");
      } else if (this.posFinal - this.posInitial > this.threshold) {
        this.shiftSlide(-1, "drag");
      } else {
        this.$refs.sliderItems.style.left = this.posInitial + "px";
      }
      document.onmouseup = null;
      document.onmousemove = null;
    },
    shiftSlide(dir, action) {
      this.slideSize = this.$refs.slides[0].offsetWidth;
      this.$refs.sliderItems.classList.add("shifting");
      if (this.allowShift) {
        if (!action) {
          this.posInitial = this.$refs.sliderItems.offsetLeft;
        }
        if (dir == 1) {
          this.$refs.sliderItems.style.left = this.posInitial - this.slideSize + "px";
          this.index++;
        } else if (dir == -1) {
          this.$refs.sliderItems.style.left = this.posInitial + this.slideSize + "px";
          this.index--;
        }
      }

      this.allowShift = false;
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.shiftSlide(1)
      }, 5000);
    },
    fixSpace() {
      this.slideSize = this.$refs.slides[0].offsetWidth;
      this.posInitial = this.$refs.sliderItems.offsetLeft;
      if (this.posInitial % this.slideSize !== 0) {
        this.fixingSpace = true;
        let diference =
          this.posInitial - Math.floor(this.posInitial / this.slideSize) * this.slideSize;
        if (diference >= this.slideSize / 2) {
          diference = this.slideSize - diference;
          this.$refs.sliderItems.style.left = this.posInitial + diference + "px";
        } else {
          this.$refs.sliderItems.style.left = this.posInitial - diference + "px";
        }
        this.fixingSpace = false;
      }
    },
    checkIndex() {
      this.$refs.sliderItems.classList.remove("shifting");
      if (this.index == -1) {
        this.$refs.sliderItems.style.left = -(this.sliders.length * this.slideSize) + "px";
        this.index = this.sliders.length - 1;
      }
      if (this.index == this.sliders.length) {
        this.$refs.sliderItems.style.left = -(1 * this.slideSize) + "px";
        this.index = 0;
      }

      this.allowShift = true;
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.shiftSlide(1)
    }, 5000)
    this.$refs.sliderItems.addEventListener('transitionend', this.checkIndex)
    if(this.$refs.slides.length === this.sliders.length) {
      let newSlides = JSON.parse(JSON.stringify(this.$refs.slides))
      newSlides.push(this.$refs.lastSlide);
      newSlides.unshift(this.$refs.firstSlide);
      this.$refs.slides = newSlides
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.fixSpace)
  },
  onUpdated() {
    if(this.$refs.slides.length === this.sliders.length) {
      let newSlides = JSON.parse(JSON.stringify(this.slides))
      this.newSlides.push(this.$refs.lastSlide);
      this.newSlides.unshift(this.$refs.firstSlide);
      this.$refs.slides = newSlides
    }
  }
}
</script>

<style>
.slider {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.slider__container {
  padding-top: 80px;
  background-color: var(--secondary);
}
.wrapper {
  overflow: hidden;
  position: relative;
  background: #222;
  z-index: 1;
}
#items {
  width: 10000px;
  position: relative;
  top: 0;
  left: -100%;
  display: flex;
}
#items.shifting {
  transition: left 0.5s ease-out;
}
#items.fix-space {
  transition: none;
}
.slide {
  width: 100vw;
  cursor: pointer;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s;
  position: relative;
}

.slide__image {
  width: 100vw;
  height: auto;
}

.control {
  position: absolute;
  top: 50%;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-top: -12.5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  cursor: pointer;
  transition: 100ms;
  transform: scale(1);
}

.control:hover {
  transition: 100ms;
  transform: scale(1.1);
}

.control__image {
  width: 25px;
  height: 25px;
}

.prev,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
}
.prev {
  left: 10px;
}
.next {
  right: 10px;
}
.prev:active,
.next:active {
  transform: scale(0.8);
}
</style>