<template>
  <div class="modal__container" :class="modalSwitch">
    <aside class="modal">
      <slot></slot>
    </aside>
  </div>
</template>

<script>

export default {
  name: 'ModalComponent',
  props: {
    active: Boolean,
  },
  data() {
    return {
      modalSwitch: {
        'modal-active': false,
        'modal-show': false,
      }
    }
  },
  watch: {
    active() {
      if(this.active) {
        this.modalSwitch['modal-show'] = true
        setTimeout(()=> {
          this.modalSwitch['modal-active'] = true
        },100)
      } else {
        this.modalSwitch['modal-active'] = false
        setTimeout(()=> {
          this.modalSwitch['modal-show'] = false
        },500)
      }
    }
  },
  mounted() {
    if(this.active) {
      this.modalSwitch['modal-show'] = true
      setTimeout(()=> {
        this.modalSwitch['modal-active'] = true
      },100)
    } else {
      this.modalSwitch['modal-active'] = false
      setTimeout(()=> {
        this.modalSwitch['modal-show'] = false
      },500)
    }
  }
}
</script>
<style>
  .modal__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.5s;
    background-color: transparent;
    display: none;
    z-index: 10;
  }

  .modal {
    transform: translateY(100vh);
    transition: transform 0.5s;
    width: max-content;
    max-width: 100vw;
    height: min-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: #fff;
  }
  .modal__container.modal-show {
    display: block;
  }

  .modal__container.modal-active {
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.5s;
  }

  .modal__container.modal-active .modal {
    transform: translateY(0);
    transition: transform 0.5s;
  }
</style>
