import Vue from 'vue'
import App from './App.vue'
import VueMask from 'v-mask'
import VueSanitize from "vue-sanitize"

Vue.config.productionTip = false

const sanitizeDefautlOptions = {
  allowedTags: ['a', 'strong', 'br']
}

Vue.use(VueMask, {
  placeholders: {
    U: /^[a-zA-Z\s]*$/
  }
})
Vue.use(VueSanitize, sanitizeDefautlOptions)
new Vue({
  render: h => h(App),
}).$mount('#app')
