<template>
  <div v-if="plans.length">
    <div id="planes-hogar" class="plan-id"></div>
    <div class="plan-collapse__title">
      <h3 class="plan-collapse__title-text">Más Planes Hogar</h3>
    </div>
    <div class="plan-collapse__container">
      <div class="plan-control__container">
        <button
          class="plan__control left"
          :class="{'desactive': desactive.left}"
          @click="()=>{move(-1)}"
          :disabled="desactive.left"
        >
          <img class="plan-control__image" src="@/assets/img/arrow-left.png" alt="">
          <div class="plan-control__desactive"></div>
        </button>
        <button
          class="plan__control right"
          :class="{'desactive': desactive.right}"
          :disabled="desactive.right"
          @click="()=>{move(1)}"
        >
          <img class="plan-control__image" src="@/assets/img/arrow-right.png" alt="">
          <div class="plan-control__desactive"></div>
        </button>
        <ul class="plan-collapse" ref="plan" @scroll="callScroll">
          <li class="plan-collapse__card" :key="index" v-for="(plan, index) in plans">
            <button class="plan-collapse-card__image-container" @click="openModalForm">
              <img class="plan-collapse-card__image" :src="'https://landing.inoutdelivery.com' + plan.attributes.url" alt="">
            </button>
            <button class="plan-collapse-card__button" @click="openModalForm">
              <img class="plan-collapse-card__button-image" src="@/assets/img/button-plan.png" alt="">
            </button>
          </li>
        </ul>
      </div>
      <div class="plan-collapse-directv__container">
        <div class="plan-collapse__directv">
          <img class="plan-collapse-directv__image" :src="'https://landing.inoutdelivery.com' + directTvImages.pc.url" alt="">
        </div>
      </div>
    </div>
    <div class="plan-collapse-directv__container mobile">
      <div class="plan-collapse__directv">
        <img class="plan-collapse-directv__image" :src="'https://landing.inoutdelivery.com' + directTvImages.mobile.url" alt="">
      </div>
    </div>
    <p class="letter-small" v-html="terms">
    </p>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
  props: {
    plans: Array,
    terms: String,
    directTvImages: Object,
  },
  data() {
    return {
      desactive: {
        left: true,
        right: false,
      },
    };
  },
  methods: {
    callScroll(e) {
      this.desactive.left = e.target.scrollLeft === 0
      this.desactive.right = e.target.scrollLeft === e.target.scrollWidth - e.target.offsetWidth
    },
    move(orientation) {
      let currentScroll = this.$refs.plan.scrollLeft;
      let itemScroll = 260;
      let position;
      if(currentScroll % itemScroll == 0) {
        position = (currentScroll / itemScroll) + orientation;
      } else
      if(orientation === 1) {
        position = Math.ceil(currentScroll / itemScroll);
      } else {
        position = Math.floor(currentScroll / itemScroll);
      }
      let newScroll = itemScroll * position;
      this.$refs.plan.scrollTo({
        top: "auto",
        left: newScroll,
        behavior: "smooth"
      });
    },
    openModalForm: () => {
      window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:"Quiero este Plan", eventLabel:"Leads"})
      EventBus.$emit("openModalForm")
    },
  },
}
</script>

<style>
@import "@/css/planes.css";
</style>