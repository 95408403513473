<template>
  <div v-if="data">
    <header class="header">
      <div class="header__container">
        <div class="header__logo">
          <img class="logo__image" src="@/assets/img/logo.png" alt="">
        </div>
        <div class="header__links">
          <a href="#planes-hogar">Hogares</a>
          <hr/>
          <a href="#planes-negocio">Negocios</a>
        </div>
        <form
          @submit.prevent="() => {
            checkboxHeader.form = false
            validateHeaderForm.validate = true
            validateHeaderForm.required = false
            validateHeaderForm.phone = false
            if(!checkboxHeader.value) {
              checkboxHeader.form = true
            } else
            if(!formHeader.phone) {
              validateHeaderForm.required = true
            } else
            if(formHeader.phone.length !== 7 && formHeader.phone.length !== 10) {
              validateHeaderForm.phone = true
            } else {
              sendData(formHeader, 'header')
              validateHeaderForm.validate = false
            }
          }"
          class="header__form"
        >
          <div class="header__form--pc">
            <div class="header__form-left">
              <h4>Nosotros te llamamos</h4>
              <div class="form__checkbox">
                <input v-model="checkboxHeader.value" type="checkbox" name="" id="older-header">
                <label class="checkbox__label--xs" for="older-header">Confirmo que soy mayor de edad y autorizo el uso mis datos personales de acuerdo a la política de Phonemaster distribuidor autorizado por ETB. </label>
              </div>
            </div>
            <div class="header__form-right">
              <div class="header-form__input-container">
                <input v-mask="'##########'" v-model="formHeader.phone" class="header-form__input" name="phone" type="tel" placeholder="Ingresa tu número">
                  <div class="error--text" v-if="!checkboxHeader.value && checkboxHeader.form">Se requiere ser mayor de edad y aceptar los terminos y condiciones</div>
                <template v-if="validateHeaderForm.validate">
                  <div class="error--text" v-if="validateHeaderForm.phone">El número debe tener 7 o 10 digitos</div>
                  <div class="error--text" v-if="validateHeaderForm.required">El núnmero es nescesario</div>
                </template>
              </div>
              <button
                class="header__button"
              >
                <img class="header-button__image" src="@/assets/img/header-form-mobile.png" alt="">
                <span class="header-button__text">
                  Llámame
                </span>
              </button>
            </div>
          </div>
          <div class="header__form--mobile">
            <div class="header-form__input-container">
              <img class="header-form__icon" src="@/assets/img/input-icon-mobile.png" alt="">
              <input
                v-mask="'##########'"
                v-model="formHeader.phone"
                class="header-form__input"
                name="phone"
                type="tel"
                placeholder="¿Te llámamos?, ingresa tu número"
              >
              <button class="header__button">
                <img class="header-button__image" src="@/assets/img/header-form-mobile.png" alt="">
                <span class="header-button__text">
                  Llámame
                </span>
              </button>
            </div>
            <div class="form__checkbox">
              <input v-model="checkboxHeader.value" type="checkbox" name="" id="older-header">
              <label class="checkbox__label--xs" for="older-header">Confirmo que soy mayor de edad y autorizo el uso mis datos personales de acuerdo a la política de Phonemaster distribuidor autorizado por ETB. </label>
            </div>
            <div class="error--text" v-if="!checkboxHeader.value && checkboxHeader.form">Se requiere ser mayor de edad y aceptar los terminos y condiciones</div>
            <template v-if="validateHeaderForm.validate">
              <div class="error--text" v-if="validateHeaderForm.phone">El número debe tener 7 o 10 digitos</div>
              <div class="error--text" v-if="validateHeaderForm.required">El núnmero es nescesario</div>
            </template>
          </div>
        </form>
        <div class="header__call">
          <a :href="'tel:'+ (data.phone || '')" class="header__call--mobile" @click="()=> {sendGEvent('Lead inbound linea de ventas')}">
            <img class="header-call__image" src="@/assets/img/header-call-mobile.png" alt="">
            <span class="header-call__text">
              Llamar {{data.phone || ''}}
            </span>
          </a>
          <div class="header__call--pc">
            <h4>Línea de <br> Ventas</h4>
            <a :href="'tel:'+ (data.phone || '')" class="header__button" @click="()=> {sendGEvent('Lead inbound linea de ventas')}">
              <div class="header-button__text">
                <p>{{data.phone || ''}}</p>
                <p>Llamar</p>
              </div>
              <img class="header-button__image" src="@/assets/img/call-button.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </header>
    <SliderComponent :sliders="filteredSlides" v-if="filteredSlides.length >1"/>
    <button
      v-else
      @click="openModalForm"
      class="only-image__container"
    >
      <img class="only-image" :src="'https://landing.inoutdelivery.com' + filteredSlides[0].attributes.url"/>
    </button>
    <div class="hero-top">
      <div class="hero-top__container">
        <div class="hero-top__title">
        <h1 v-html="markdowntoHtml(data.copy_boton_interna)"></h1>
        </div>
        <form
          @submit.prevent="() => {
            checkboxFullForm.form = false
            validateFullForm.validate = true
            validateFullForm.required = false
            validateFullForm.phone = false
            if(!checkboxFullForm.value) {
              checkboxFullForm.form = true
            } else
            if(formFull.phone === '' || formFull.name === '') {
              validateFullForm.required = true
            } else
            if(formFull.phone.length !== 7 && formFull.phone.length !== 10) {
              validateFullForm.phone = true
            } else {
              sendData(formFull, 'body')
              validateFullForm.validate = false
            }
          }"
          class="hero-form"
        >
          <input v-mask="'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU'" v-model="formFull.name" class="custom-input" name="name" placeholder="Ingresa tu nombre" type="text">
          <input v-mask="'##########'" v-model="formFull.phone" class="custom-input" name="phone" placeholder="Ingresa tu número" type="tel">
          <div class="form__checkbox">
            <input class="form" v-model="checkboxFullForm.value" type="checkbox" name="older" id="older-hero">
            <label class="checkbox__label" for="older-hero">Confirmo que soy mayor de edad y autorizo el uso mis datos personales de acuerdo a la política de Phonemaster distribuidor autorizado por ETB.</label>
          </div>
          <button class="custom-button">
            <img class="custom-button__image" src="@/assets/img/button-hero.png" alt="">
            <span>Llámame</span>
          </button>
          <small class="error--text" v-if="!checkboxFullForm.value && checkboxFullForm.form">Se requiere ser mayor de edad y aceptar los terminos y condiciones</small>
          <template v-if="validateFullForm.validate">
            <small class="error--text" v-if="validateFullForm.phone">El número debe tener 7 o 10 digitos</small>
            <small class="error--text" v-if="validateFullForm.required">Todos los campos son requeridos</small>
          </template>
        </form>
      </div>
    </div>
    <div class="hero-bottom">
      <div class="hero-bottom__container">
        <h2 v-html="markdowntoHtml(data.copy_promesa)"></h2>
        <p>Aplican <a href="#teminos-y-condiciones">términos y condiciones</a> política exclusiva de PHONEMASTER</p>
      </div>
    </div>
    <main class="main">
      <div class="main__tag">
        <h2 v-html="markdowntoHtml(data.copy_titulo)"></h2>
      </div>
      <div class="main__container">
        <div class="main__description" v-html="markdowntoHtml(data.copy_cuerpo)">
        </div>
        <ul class="plan-list" v-if="promociones_mes.length">
          <li class="plan-card" v-for="(promotion, index) in promociones_mes" :key="index">
            <button class="plan-card__image-container" @click="openModalForm">
              <img class="plan-card__image" :src="'https://landing.inoutdelivery.com' + promotion.attributes.url" alt="">
            </button>
            <button class="plan-card__button" @click="openModalForm">
              <img class="plan-card__button-image" src="@/assets/img/button-plan.png" alt="">
            </button>
          </li>
        </ul>
      </div>
      <HomePlans :directTvImages="directTvHogar" :plans="planes_mes_detalle" :terms="markdowntoHtml(data.terminos_condiciones_mes)"/>
      <BussinesPlans :directTvImages="directTvNegocios" :plans="planes_mes_detalle_negocios" :terms="markdowntoHtml(data.terminos_condicciones_mes_negocios)"/>
      <div class="banner-bottom">
        <div class="banner-bottom__container">
          <div class="banner-bottom__section">
            <h2 class="banner-bottom__title" v-html="markdowntoHtml(data.copy_promesa_footer)"></h2>
            <h4>
              Aplican <a href="#teminos-y-condiciones">términos y condiciones</a> política exclusiva
              de PHONEMASTER
            </h4>
          </div>
          <hr>
          <div class="banner-bottom__section right">
            <h4 class="banner-bottom-section__text right" v-html="markdowntoHtml(data.copy_promesa_footer_detalle)">
            </h4>
          </div>
        </div>
      </div>
    </main>
    <div class="buttons-call">
      <a v-if="false" class="button-call-whatsapp button-call" :href="'https://wa.me/'+(data.phone || '')">
        <img class="button-call__image" src="@/assets/img/button-whatsapp.png" alt="">
        <img class="button-call__image mobile" src="@/assets/img/whatsapp-icon.png" alt="">
      </a>
      <button class="button-call" onclick="startNeoWebChat();" @click="()=> {sendGEvent('Lead chat')}">
        <img class="button-call__image" src="@/assets/img/chat-icon.png" alt="">
        <img class="button-call__image mobile" src="@/assets/img/chat-icon.png" alt="">
      </button>
      <a class="button-call-phone button-call" :href="'tel:'+(data.phone || '')" @click="()=> {sendGEvent('lead inbound flotante')}">
        <img class="button-call__image" src="@/assets/img/call-icon.png" alt="">
        <img class="button-call__image mobile" src="@/assets/img/call-icon.png" alt="">
      </a>
    </div>
    <footer class="footer">
      <div class="footer__container">
        <div class="footer__section-top">
          <img class="footer__logo" src="@/assets/img/logo.png" alt="">
          <ul class="footer__list first">
            <li>Planes internet hogar</li>
            <li>Planes Internet Negocios</li>
          </ul>
          <ul class="footer__list second">
            <li><a href="tel:305 934 1000">305 934 1000</a></li>
            <li>Cra 27B No. 74-34. Bogotá</li>
            <li>
              <a href="mailto:ventasetb@phonemaster.com.co">ventasetb@phonemaster.com.co</a>
            </li>
          </ul>
          <div class="footer__button-container">
            <h3>Línea de Ventas</h3>
            <a :href="'tel:'+(data.phone || '')" class="footer__button" @click="()=>{openModalForm}">
              <h2>{{data.phone}}</h2>
              <span>Llamar</span>
            </a>
          </div>
        </div>
        <div  class="footer__section-bottom">
          <div class="footer-bottom__logos">
            <img class="logo--min" src="@/assets/img/phonemaster-logo.png" alt="">
            <span>Distribuidor autorizado para </span>
            <img class="logo--min" src="@/assets/img/logo.png" alt="">
          </div>
          <div class="footer__links">
            <a href="#teminos-y-condiciones">Política de privacidad </a>
            <hr>
            <a href="#teminos-y-condiciones">Términos y Condiciones </a>
            <hr>
            <a href="#teminos-y-condiciones">Política de cookies </a>
            <hr>
            <button @click="openModalForm">Contáctenos</button>
          </div>
        </div>
      </div>
    </footer>
    <ModalForm
      :title="markdowntoHtml(data.copy_pop_pup)"
      :image="'https://landing.inoutdelivery.com'+data.imagen_pop_pup.data.attributes.url"
    />
    <ModalInfo/>
    <ModalTermsConditions/>
  </div>
</template>

<script>
import SliderComponent from '@/components/SliderComponent.vue'
import ModalTermsConditions from '@/components/ModalTermsConditions.vue'
import HomePlans from '@/components/HomePlans.vue'
import BussinesPlans from '@/components/BussinesPlans.vue'
import ModalForm from '@/components/ModalForm.vue'
import ModalInfo from '@/components/ModalInfo.vue'
import { EventBus } from '@/event-bus';
import {marked} from 'marked';

export default {
  name: 'App',
  components: {
    HomePlans,
    BussinesPlans,
    SliderComponent,
    ModalForm,
    ModalInfo,
    ModalTermsConditions,
  },
  data() {
    return {
      data: null,
      banners: [],
      promociones_mes: [],
      planes_mes_detalle: [],
      planes_mes_detalle_negocios: [],
      formHeader: {
        phone: ''
      },
      directTvHogar: {
        mobile:{},
        pc: {}
      },
      directTvNegocios: {
        mobile:{},
        pc: {}
      },
      checkboxFullForm: {
        value: true,
        form: false,
      },
      validateFullForm: {
        required: false,
        phone: false,
        validate: false
      },
      validateHeaderForm: {
        required: false,
        phone: false,
        validate: false
      },
      checkboxHeader: {
        value: true,
        form: false
      },
      formFull: {
        phone: '',
        name: '',
      },
      isMobile: false,
      isTablet: false,
    }
  },
  computed: {
    filteredSlides() {
      const newSlides = this.banners.filter((item) => {
        if(this.isMobile) {
          return item.attributes.caption === 'mobile'
        } else
        if(this.isTablet){
          return item.attributes.caption === 'tablet'
        } else {
          return item.attributes.caption === 'pc'
        }
      })
      return newSlides
    }
  },
  methods: {
    getData: async () => {
      try {
        const response = await fetch("https://landing.inoutdelivery.com/api/etbs/1?populate=banners,promociones_mes,planes_mes_detalle,imagen_pop_pup,servicios_adicional_hogar,servicios_adicional_negocios,planes_mes_detalle_negocios");
        const data = await response.json();
        return data.data
      } catch (error) {
        console.error(error)
      }
    },
    sendData: async (form, type) => {
      try {
        let messageRegister = type == 'header' ? "Lead registro header" : "Lead registro body"
        window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:messageRegister, eventLabel:"Leads"})
        const response = await fetch('https://landing.inoutdelivery.com/api/neo', {
          method: 'POST',
          body: JSON.stringify(form),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if(response.ok) {
          EventBus.$emit("openModalInfo")
        }
        return response
      } catch (error) {
        console.error(error)
      }
    },
    sendGEvent(message) {
      window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:message, eventLabel:"Leads"})
    },
    validateMobile(x) {
      if (x.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    validateTablet(x) {
      if (x.matches) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    },
    markdowntoHtml(markdown) {
      if(markdown) {
        return this.$sanitize(marked(markdown))
      }
      return ''
    },
    openModalForm: () => {
      window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:"Lead quiero este Plan", eventLabel:"Leads"})
      EventBus.$emit("openModalForm")
    },
    // ClicLlamar() {
    //   let docu = document.getElementsByClassName("header__button")[1]
    //   let docuFlotante = document.getElementsByClassName("button-call-phone")[0]
    //   let clickHandler = function () {
    //     window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:"Llamar header", eventLabel:"Leads"})
    //   }
    //   let clickHandlerFloat = function () {
    //     window.dataLayer.push({event:"clic",eventCategory: "CallToAction",eventAction:"lead inbound flotante", eventLabel:"Leads"})
    //   }
    //   docuFlotante.addEventListener("click", clickHandlerFloat)
    //   docu.addEventListener('click', clickHandler)
    // }
  },
  mounted() {
    window.onload = () => {
      let frameELement = document.getElementById("iframeWebChat")
      let doc = frameELement.contentDocument || frameELement.contentWindow.document
      doc.body.innerHTML = doc.body.innerHTML + '<style>#cntDatos1Temp{margin-left: -40px; position: relative;}</style>'
    }
    let mediaMobile = window.matchMedia("(max-width: 450px)")
    this.validateMobile(mediaMobile)
    mediaMobile.addListener(this.validateMobile)
    let mediaTablet = window.matchMedia("(max-width: 750px)")
    this.validateTablet(mediaTablet)
    mediaTablet.addListener(this.validateTablet)
    window.addEventListener('resize', this.fixSpace)
    // this.ClicLlamar()
  },
  created() {
    this.getData().then((response) => {
      this.data = response.attributes
      this.banners = this.data.banners.data
      this.promociones_mes = this.data.promociones_mes.data
      this.planes_mes_detalle = this.data.planes_mes_detalle.data
      this.planes_mes_detalle_negocios = this.data.planes_mes_detalle_negocios.data
      this.data.servicios_adicional_hogar.data.forEach(service => {
        if(service.attributes.caption === 'mobile') {
          this.directTvHogar.mobile = service.attributes
        } else
        if(service.attributes.caption === 'pc') {
          this.directTvHogar.pc = service.attributes
        }
      });
      this.data.servicios_adicional_negocios.data.forEach(service => {
        if(service.attributes.caption === 'mobile') {
          this.directTvNegocios.mobile = service.attributes
        } else
        if(service.attributes.caption === 'pc') {
          this.directTvNegocios.pc = service.attributes
        }
      });
    })
  },
}
</script>

<style>
@import "./global.css"
</style>
